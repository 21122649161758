/* Importa il font DM Sans */
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap');

body {
  margin: 0;
  font-family: 'DM Sans', sans-serif;
}

.chi-siamo {
  background: linear-gradient(135deg, #1f1c2c, #928dab);
  color: white;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.title {
  font-size: 32px;
  margin-bottom: 20px;
}

.description {
  font-size: 18px;
  margin-bottom: 20px;
  line-height: 1.6;
}

.subtitle {
  font-size: 24px;
  margin-bottom: 15px;
}

.content {
  font-size: 18px;
  margin-bottom: 20px;
  line-height: 1.6;
  text-align: left;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

.content p {
  margin-bottom: 10px;
}

a {
  color: #FFD700;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}
