/* Importa il font DM Sans */
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap');

body {
  margin: 0;
  font-family: 'DM Sans', sans-serif;
}

.footer {
  background: linear-gradient(135deg, #1f1c2c, #928dab);
  color: white;
  padding: 20px 40px;
  text-align: center;
  border-radius: 0 0 10px 10px;
  box-shadow: 0 -4px 8px rgba(0, 0, 0, 0.1);
}

.footer p {
  margin: 0;
  font-size: 14px;
}

.social-icons {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}

.social-icon {
  margin: 0 10px;
  color: white;
  text-decoration: none;
  font-size: 24px;
  transition: color 0.3s ease, transform 0.3s ease;
}

.social-icon:hover {
  color: #FFD700;
  transform: scale(1.1);
}

