/* Importa il font DM Sans */
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap');

/* Variabili per il componente TrattamentoDati */
:root {
  --td-bg-start: #1f1c2c;
  --td-bg-end: #928dab;
  --td-text-color: #ffffff;
  --td-highlight-color: #ffd700;
  --td-title-size: 36px;
  --td-subtitle-size: 26px;
  --td-content-size: 18px;
}

/* Reset e layout globale */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}



/* Contenitore principale del componente */
.trattamento-dati {
  background: linear-gradient(135deg, var(--td-bg-start), var(--td-bg-end));
  color: var(--td-text-color);
  padding: 50px 30px;
  text-align: center;
  min-height: 100vh;
  overflow: hidden;
}

/* Titolo principale */
.trattamento-dati .title {
  font-size: var(--td-title-size);
  margin-bottom: 30px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.4);
  animation: tdFadeIn 1s ease-out forwards;
}

/* Sottotitoli con icone animate */
.trattamento-dati .subtitle {
  font-size: var(--td-subtitle-size);
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: tdFadeIn 1s ease-out forwards;
}

.trattamento-dati .subtitle .icon {
  margin-right: 10px;
  font-size: 1.4em;
  transition: transform 0.3s ease;
}

.trattamento-dati .subtitle:hover .icon {
  transform: rotate(360deg);
}

/* Contenuto testuale */
.trattamento-dati .content {
  font-size: var(--td-content-size);
  margin-bottom: 25px;
  line-height: 1.8;
  text-align: left;
  max-width: 900px;
  margin: 0 auto 25px auto;
  animation: tdFadeIn 1s ease-out forwards;
}

/* Liste e voci elenco */
.trattamento-dati .list {
  text-align: left;
  max-width: 900px;
  margin: 0 auto 25px auto;
  padding-left: 25px;
  animation: tdFadeIn 1s ease-out forwards;
}

.trattamento-dati .list li {
  margin-bottom: 15px;
  font-size: var(--td-content-size);
  line-height: 1.6;
}

/* Stili per i collegamenti */
.trattamento-dati a {
  color: var(--td-highlight-color);
  text-decoration: none;
  border-bottom: 1px dashed var(--td-highlight-color);
  transition: color 0.3s ease, border-bottom-color 0.3s ease;
}

.trattamento-dati a:hover {
  color: var(--td-highlight-color);
  border-bottom-color: transparent;
}

/* Animazione unica per il componente */
@keyframes tdFadeIn {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Cascading animation per le sezioni */
.trattamento-dati section {
  opacity: 0;
  animation: tdFadeIn 1s ease-out forwards;
}

/* Assegna delay automatici alle diverse sezioni per effetto cascata */
.trattamento-dati section:nth-of-type(1) {
  animation-delay: 0.2s;
}
.trattamento-dati section:nth-of-type(2) {
  animation-delay: 0.4s;
}
.trattamento-dati section:nth-of-type(3) {
  animation-delay: 0.6s;
}
.trattamento-dati section:nth-of-type(4) {
  animation-delay: 0.8s;
}
.trattamento-dati section:nth-of-type(5) {
  animation-delay: 1s;
}
.trattamento-dati section:nth-of-type(6) {
  animation-delay: 1.2s;
}
.trattamento-dati section:nth-of-type(7) {
  animation-delay: 1.4s;
}
.trattamento-dati section:nth-of-type(8) {
  animation-delay: 1.6s;
}


