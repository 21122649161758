.container {
  margin: 0;
  font-family: 'Arial', sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: linear-gradient(135deg, #007BFF, #00CFFF);
  color: white;
  flex-direction: column;
}

.message-box {
  background: white;
  border-radius: 20px;
  width: 90%;
  max-width: 400px;
  padding: 30px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  margin: 20px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  max-height: 80vh; /* Limita l'altezza massima della finestra */
  overflow-y: auto; /* Aggiunge lo scroll verticale */
}

.message-box:hover {
  transform: translateY(-10px);
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
}

.message-header {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.profile-pic {
  background-image: url('https://placehold.co/50x50');
  background-size: cover;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  margin-right: 15px;
}

.username {
  font-weight: bold;
  color: black;
  font-size: 20px;
}

.prompt-text {
  color: #555;
  margin-bottom: 10px;
  text-align: center;
  font-size: 18px;
}

.message-input {
  width: 100%;
  padding: 15px;
  border-radius: 10px;
  border: none;
  background: rgba(0, 123, 255, 0.1);
  color: #555;
  transition: background 0.3s ease, color 0.3s ease;
  font-size: 16px;
}

.message-input:focus {
  background: rgba(0, 123, 255, 0.2);
  color: #000;
}

.file-upload {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.file-upload input {
  display: none;
}

.custom-file-label {
  background: #007BFF;
  color: white;
  padding: 10px 20px;
  border-radius: 30px;
  cursor: pointer;
  transition: background 0.3s ease;
}

.custom-file-label:hover {
  background: #0056b3;
}

.preview {
  max-width: 100%;
  max-height: 200px;
  margin-top: 10px;
}

.footer {
  text-align: center;
  margin-top: 50px;
}

.footer p {
  margin: 5px 0;
  font-size: 14px;
}

.button {
  background: black;
  color: white;
  padding: 15px 30px;
  border-radius: 30px;
  text-decoration: none;
  display: inline-block;
  margin-top: 10px;
  font-weight: bold;
  transition: background 0.3s ease;
}

.button:hover {
  background: #333;
}

.privacy {
  font-size: 12px;
  color: rgba(255, 255, 255, 0.7);
}

.button-group {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.upload-button {
  background: #007BFF;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 30px;
  cursor: pointer;
  transition: background 0.3s ease;
}

.upload-button:hover {
  background: #0056b3;
}

.upload-button.shake-animation {
  animation: shake 3s infinite;
}

@keyframes shake {
  0%, 100% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(-5px);
  }
  50% {
    transform: translateX(5px);
  }
  75% {
    transform: translateX(-5px);
  }
}

.mode-selector {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.nav-button {
  background: black;
  color: white;
  padding: 10px;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  transition: background 0.3s ease;
}

.nav-button i {
  font-size: 16px;
}

.nav-button:hover {
  background: #333;
}

@media (max-width: 768px) {
  .message-box {
    width: 80%;  /* più modesto su schermi più piccoli */
    padding: 20px;
  }
  
  .profile-pic {
    width: 50px;
    height: 50px;
  }
  
  .username {
    font-size: 18px;
  }
  
  .prompt-text {
    font-size: 16px;
  }
  
  .message-input {
    padding: 10px;
    font-size: 14px;
  }
  
  .button {
    padding: 10px 20px;
  }
}

.aiinput-prompt-container {
  background-color: #007BFF;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  margin-top: 20px;
}

.aiinput-prompt-label {
  color: white;
  font-size: 18px;
  margin-bottom: 10px;
  display: block;
}

.aiinput-input-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.aiinput-prompt-input {
  font-size: 25px;
  color: black;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 70%;
  padding: 10px;
  font-weight: bold;
  border-radius: 10px;
  border: none;
}

.aiinput-icon-button {
  background-color: black;
  border: none;
  color: white;
  padding: 10px 20px;
  border-radius: 10px; /* Bordi arrotondati */
  cursor: pointer;
  margin-left: 10px;
}

.aiinput-image-placeholder {
  margin-top: 20px;
  padding: 20px;
  background-color: #e1e7f2;
  border-radius: 10px;
}

.aiinput-placeholder-text {
  color: #555;
}















  
