@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;700&display=swap');

.aboutSocial {
  font-family: 'Montserrat', sans-serif;
  color: #333;
  overflow-x: hidden;
}

/* Hero Section */
.heroSection {
  background: linear-gradient(135deg, #6e8efb 0%, #a777e3 100%);
  color: white;
  padding: 120px 20px;
  text-align: center;
}

.heroContent {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.textContent {
  max-width: 800px;
}

.title {
  font-size: 3.5rem;
  font-weight: 700;
  margin-bottom: 30px;
}

.highlight {
  color: #ffd700;
}

.description {
  font-size: 1.5rem;
  line-height: 1.6;
  margin-bottom: 40px;
}

.ctaButtons {
  display: flex;
  flex-direction: row-reverse;
  gap: 20px;
  margin-top: 50px;
  align-items: center; /* Centra i pulsanti */
}

.ctaButton,
.secondaryButton {
  padding: 18px 36px;
  border-radius: 50px;
  font-size: 1.2rem;
  font-weight: 600;
  text-decoration: none;
  transition: background-color 0.3s, transform 0.3s;
  width: 130px;
  text-align: center;
}

.ctaButton {
  background-color: #ffd700;
  color: #333;
}

.ctaButton:hover {
  background-color: #e6c200;
  transform: translateY(-5px);
}

.secondaryButton {
  background-color: transparent;
  border: 2px solid #ffd700;
  color: #ffd700;
}

.secondaryButton:hover {
  background-color: #ffd700;
  color: #333;
  transform: translateY(-5px);
}

.imageContent {
  margin-top: 50px;
}

.heroIcon {
  font-size: 180px;
  color: white;
  animation: float 6s ease-in-out infinite;
}

@keyframes float {
  0%,
  100% {
    transform: translateY(0%);
  }
  50% {
    transform: translateY(-5%);
  }
}

/* Features Section */
.featuresSection {
  padding: 60px 20px;
  background-color: #f9f9f9;
  text-align: center;
}

.subtitle {
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 10px;
}

.sectionDescription {
  font-size: 1rem;
  margin-bottom: 40px;
  color: #666;
}

.featuresGrid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
}

.featureItem {
  background-color: white;
  border-radius: 15px;
  padding: 20px;
  text-align: center;
  transition: transform 0.3s;
}

.featureItem:hover {
  transform: translateY(-10px);
}

.featureIcon {
  font-size: 50px;
  color: #6e8efb;
  margin-bottom: 15px;
}

.featureTitle {
  font-size: 1.25rem;
  font-weight: 600;
  margin-bottom: 10px;
}

.featureDescription {
  font-size: 1rem;
  line-height: 1.5;
  color: #555;
}

/* Testimonials Section */
.testimonialsSection {
  padding: 60px 20px;
  background-color: #fff;
  text-align: center;
}

.testimonialsGrid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
}

.testimonialItem {
  background-color: #f1f1f1;
  border-radius: 15px;
  padding: 20px;
  font-style: italic;
  position: relative;
}

.testimonialIcon {
  font-size: 40px;
  color: #6e8efb;
  position: absolute;
  top: -20px;
  left: calc(50% - 20px);
  background-color: #fff;
  border-radius: 50%;
  padding: 5px;
}

.testimonialItem p {
  margin-bottom: 15px;
}

.testimonialItem span {
  font-weight: 600;
  color: #333;
}

/* FAQ Section */
.faqSection {
  padding: 60px 20px;
  background-color: #f9f9f9;
  text-align: center;
}

.faqItem {
  display: flex;
  align-items: flex-start;
  margin-bottom: 20px;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

.faqIcon {
  font-size: 30px;
  color: #6e8efb;
  margin-right: 15px;
}

.faqQuestion {
  font-size: 1.2rem;
  font-weight: 600;
  margin-bottom: 5px;
}

.faqAnswer {
  font-size: 1rem;
  line-height: 1.5;
  color: #555;
}

/* Call to Action Section */
.callToActionSection {
  padding: 60px 20px;
  background: linear-gradient(135deg, #a777e3 0%, #6e8efb 100%);
  color: white;
  text-align: center;
}

.ctaTitle {
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 20px;
}

.ctaDescription {
  font-size: 1.2rem;
  margin-bottom: 30px;
  line-height: 1.5;
}

.ctaButtonLarge {
  display: inline-block;
  padding: 15px 30px;
  background-color: #ffd700;
  color: #333;
  font-size: 1.25rem;
  font-weight: 600;
  border-radius: 50px;
  text-decoration: none;
  transition: background-color 0.3s, transform 0.3s;
}

.ctaButtonLarge:hover {
  background-color: #e6c200;
  transform: translateY(-5px);
}


/* Responsive Design */
@media (min-width: 768px) {
  .heroContent {
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  .textContent {
    text-align: left;
    margin-right: 40px;
  }

  .imageContent {
    margin-top: 0;
  }

  .ctaButtons {
    flex-direction: row;
    justify-content: flex-start;
  }

  .ctaButton,
  .secondaryButton {
    width: auto;
  }

  .testimonialsGrid {
    grid-template-columns: repeat(2, 1fr);
  }

  .faqItem {
    max-width: 800px;
    margin: 0 auto 20px auto;
  }
}

@media (max-width: 767px) {
  .title {
    font-size: 2.5rem;
  }

  .description {
    font-size: 1.2rem;
  }

  .ctaButton,
  .secondaryButton {
    width: 100%;
    font-size: 1rem; /* Riduce la dimensione del testo dei pulsanti su mobile */
    padding: 14px 28px; /* Riduce il padding interno dei pulsanti su mobile */
  }

  .ctaButtons {
    align-items: center; /* Centra i pulsanti su mobile */
  }

  .heroIcon {
    font-size: 150px;
  }

  .textContent,
  .imageContent {
    max-width: 100%;
  }

  .testimonialsGrid,
  .featuresGrid {
    grid-template-columns: 1fr;
  }
}







