/* Importa il font DM Sans */
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap');

body {
  margin: 0;
  font-family: 'DM Sans', sans-serif;
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background: linear-gradient(90deg, rgba(0,0,0,1) 0%, rgba(55,55,55,1) 50%, rgba(0,0,0,1) 100%);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.navbar:hover {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.navbar-logo {
  width: 50px;
  transition: transform 0.3s ease;
}

.navbar-logo:hover {
  transform: rotate(360deg);
}

.navbar-content {
  display: flex;
  align-items: center;
  gap: 15px;
  transition: opacity 0.3s ease;
}

.navbar-link {
  position: relative;
  color: white;
  text-decoration: none;
  font-size: 18px;
  transition: color 0.3s ease, transform 0.3s ease;
}

.navbar-link.active {
  font-weight: bold;
}

.navbar-link:hover,
.navbar-link:focus {
  color: #FFD700;
  transform: scale(1.1);
}

.navbar-link::after {
  content: '';
  display: block;
  width: 8px;
  height: 8px;
  background-color: #FFD700;
  border-radius: 50%;
  position: absolute;
  bottom: -5px;
  left: 50%;
  transform: translateX(-50%) scale(0);
  transition: transform 0.3s ease;
}

.navbar-link:hover::after,
.navbar-link:focus::after {
  transform: translateX(-50%) scale(1);
}

.navbar-buttons {
  display: flex;
  align-items: center;
  gap: 10px;
  transition: opacity 0.3s ease;
}

.navbar-button {
  background: none;
  border: none;
  color: white;
  font-size: 24px;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.navbar-button:hover,
.navbar-button:focus {
  transform: scale(1.2);
}

.navbar-toggle {
  display: none;
  background: none;
  border: none;
  color: white;
  font-size: 30px;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.navbar-toggle:hover,
.navbar-toggle:focus {
  transform: rotate(90deg);
}

@media (max-width: 768px) {
  .navbar-content,
  .navbar-buttons {
    display: none;
    flex-direction: column;
  }

  .navbar-content.open,
  .navbar-buttons.open {
    display: flex;
    width: 100%;
  }

  .navbar-toggle {
    display: block;
  }

  .navbar-logo {
    margin-right: 15px;
    margin-left: 5px; /* Allinea a sinistra il logo */
  }

  .navbar-link {
    display: block;
    width: 100%;
    padding: 10px;
    text-align: center;
    white-space: pre-wrap; /* Permette al testo di andare a capo */
  }

  .navbar-buttons {
    margin-top: 10px;
    width: 100%;
    text-align: center;
  }

  .navbar-button {
    width: 100%;
    padding: 10px;
  }
}





