.featuresSection {
    padding: 2rem;
    text-align: center;
  }
  
  .subtitle {
    font-size: 2rem;
    margin-bottom: 1rem;
  }
  
  .sectionDescription {
    font-size: 1.2rem;
    margin-bottom: 2rem;
  }
  
  /* -------------------- */
  /* CAROSELLO DESKTOP    */
  /* -------------------- */
  .desktopCarousel {
    display: block;
    overflow: hidden;
    margin: 0 auto;
    max-width: 1000px; /* Larghezza maggiore per un aspetto imponente */
  }
  
  .featuresSlider {
    display: flex;
    transition: transform 0.5s ease-in-out;
  }
  
  /* Ogni funzionalità occupa 1/3 della larghezza visibile */
  .featureItem {
    flex: 0 0 calc(100% / 3);
    box-sizing: border-box;
    padding: 1rem;
  }
  
  .featureIcon {
    font-size: 3.5rem;
    color: #ff9800;
    margin-bottom: 1rem;
  }
  
  .featureTitle {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
  }
  
  .featureDescription {
    font-size: 1rem;
  }
  
  /* ---------------- */
  /* LISTA MOBILE     */
  /* ---------------- */
  .mobileList {
    display: none;
  }
  
  /* ---------------- */
  /* RESPONSIVE       */
  /* ---------------- */
  @media (max-width: 768px) {
    .desktopCarousel {
      display: none;
    }
    .mobileList {
      display: block;
    }
    .featureItem {
      width: 100%;
      margin-bottom: 1.5rem;
    }
  }
  
  