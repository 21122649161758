/* Variabili per il componente FAQ */
:root {
    --faq-bg: #ffffff;        /* Sfondo bianco */
    --faq-text: #000000;       /* Testo nero */
    --faq-highlight: #000000;  /* Colore per icone e interazioni */
    --faq-question-size: 20px;
    --faq-answer-size: 18px;
  }
  
  /* Contenitore principale: larghezza 100% per occupare tutta la pagina */
  .faqSection {
    width: 100%;
    background: var(--faq-bg);
    color: var(--faq-text);
    padding: 30px;
    margin: 0;
  }
  
  /* Titolo della sezione FAQ */
  .subtitle {
    font-size: 28px;
    margin-bottom: 20px;
    text-align: center;
  }
  
  /* Ogni item della FAQ */
  .faqItem {
    cursor: pointer;
    padding: 15px 20px;
    transition: background-color 0.3s ease;
  }
  .faqItem:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }
  
  /* Container per la domanda */
  .faqQuestionContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  /* Icona della domanda */
  .faqIcon {
    margin-right: 10px;
    color: var(--faq-highlight);
    font-size: 1.2em;
  }
  
  /* Testo della domanda */
  .faqQuestion {
    flex: 1;
    font-size: var(--faq-question-size);
    margin: 0;
    text-align: left;
    color: var(--faq-text);
  }
  
  /* Icona di toggle */
  .toggleIcon {
    color: var(--faq-highlight);
    font-size: 1em;
    margin-left: 10px;
  }
  
  /* Contenitore della risposta: in stato collapse */
  .faqAnswer {
    max-height: 0;
    opacity: 0;
    overflow: hidden;
    transition: max-height 0.5s ease, opacity 0.5s ease;
  }
  
  /* Stato "aperto" della risposta */
  .faqAnswer.open {
    max-height: 200px; /* Regola in base al contenuto se necessario */
    opacity: 1;
    margin-top: 10px;
  }
  
  /* Stile del testo della risposta */
  .faqAnswer p {
    font-size: var(--faq-answer-size);
    margin: 0;
    color: var(--faq-text);
    line-height: 1.4;
  }
  
  