/* Importa il font DM Sans */
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap');

body {
  margin: 0;
  font-family: 'DM Sans', sans-serif;
}

.contattaci {
  background: linear-gradient(135deg, #1f1c2c, #928dab);
  color: white;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.title {
  font-size: 32px;
  margin-bottom: 20px;
}

.description {
  font-size: 18px;
  margin-bottom: 20px;
  line-height: 1.6;
}

.contact-info {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.contact-method {
  background: white;
  color: black;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 250px;
  text-align: left;
}

.contact-method h2 {
  margin-top: 0;
}

.contact-method a {
  color: #1f1c2c;
  text-decoration: none;
}

.contact-method a:hover {
  text-decoration: underline;
}

.subtitle {
  font-size: 24px;
  margin-top: 40px;
  margin-bottom: 15px;
}

.contact-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
}

.contact-form label {
  width: 100%;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  text-align: left;
}

.contact-form input,
.contact-form textarea {
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 5px;
  margin-top: 5px;
}

.contact-form button {
  padding: 10px 20px;
  font-size: 18px;
  color: white;
  background-color: #FFD700;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.contact-form button:hover {
  background-color: #FFC107;
  transform: scale(1.05);
}
